import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./index.module.scss";

const ContactConsentNotice = ({ className }) => {
  return (
    <small className={classNames(styles.contactConsentNotice, className)}>
      By continuing, I agree to the Equinox{" "}
      <a
        aria-label="Terms & Conditions (Opens in a new window)"
        href="/terms"
        rel="noopener noreferrer"
        target="_blank"
      >
        Terms & Conditions
      </a>
      ,{" "}
      <a
        aria-label="Privacy Policy (Opens in a new window)"
        href="/privacy"
        rel="noopener noreferrer"
        target="_blank"
      >
        Privacy Policy
      </a>{" "}
      and that Equinox brand companies and their membership advisors can contact
      me regarding promotions, marketing, products, services, and other
      information that may interest me.
    </small>
  );
};

ContactConsentNotice.propTypes = {
  className: PropTypes.string,
};

export default ContactConsentNotice;
