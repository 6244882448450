import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const renderMarkdown = (text, options = {}) => {
  if (typeof text === "string") {
    return (
      <ReactMarkdown
        components={{
          ...(!options?.paragraphs && { p: React.Fragment }),
          del: ({ children }) => <del aria-label="Deleted">{children}</del>,
        }}
        remarkPlugins={[remarkGfm]}
      >
        {text.replaceAll("'", "’")}
      </ReactMarkdown>
    );
  }

  return text;
};
