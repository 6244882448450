import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { ASSETS_CDN_HOST, BREAKPOINTS, MESSAGES } from "@/constants";
import useEqxObj from "@/hooks/useEqxObj";

import styles from "./ResponsiveImage.module.scss";

const formats = [
  {
    filenameExtension: "webp",
    mediaType: "image/webp",
  },
  {
    filenameExtension: "jpg",
    mediaType: "image/jpeg",
  },
];

/**
 * https://www.contentful.com/developers/docs/references/images-api/
 */
const ResponsiveImage = ({
  altText = MESSAGES.DEFAULT_ALT_TEXT,
  className,
  defaultImage,
  hgImage,
  layoutBlock = 50,
  layoutInline = 50,
  lazy = true,
  lgImage,
  maxWidth = 4000,
  mdImage,
  smImage,
  swapKey,
  swapTimeout = 0,
  title = MESSAGES.TAGLINE,
  xlImage,
  xsImage,
}) => {
  const { isLoading, value: eqxObjValue } = useEqxObj(swapKey, swapTimeout);
  const [imageUrlsByBreakpointLabel, setImageUrlsByBreakpointLabel] = useState({
    [BREAKPOINTS.NONE.label]:
      defaultImage?.fields.file.url || `${ASSETS_CDN_HOST}/images/clear.png`,
    [BREAKPOINTS.XS.label]: xsImage?.fields.file.url,
    [BREAKPOINTS.SM.label]: smImage?.fields.file.url,
    [BREAKPOINTS.MD.label]: mdImage?.fields.file.url,
    [BREAKPOINTS.LG.label]: lgImage?.fields.file.url,
    [BREAKPOINTS.XL.label]: xlImage?.fields.file.url,
    [BREAKPOINTS.HG.label]: hgImage?.fields.file.url,
  });

  const defaultImageUrl = imageUrlsByBreakpointLabel[BREAKPOINTS.NONE.label];

  /**
   *
   */
  const imageUrlForBreakpointValue = (breakpointValue) => {
    let imageUrl = defaultImageUrl;

    BREAKPOINTS.default.some((breakpoint) => {
      if (
        breakpoint.value <= breakpointValue &&
        imageUrlsByBreakpointLabel[breakpoint.label]
      ) {
        imageUrl = imageUrlsByBreakpointLabel[breakpoint.label];

        return true;
      }
    });

    return imageUrl;
  };

  /**
   * If no swap key is passed, show the default images.
   * Otherwise, if window.eqxObj[swapKey] exists when this component loads, show them;
   * Otherwise, wait a short bit to see if they get set (in which case, show them);
   * Otherwise, show the default images.
   *
   * window.eqxObj[swapKey] should look like:
    window.eqxObj = window.eqxObj || {};
    window.eqxObj["web1234-swap-images"] = {
      none: "https://images.ctfassets.net/drib7o8rcbyf/6NxESixHisIb2jNrKPlYO/9092dd5139faad1b30aec5327dae3808/red.png",
      xs: "https://images.ctfassets.net/drib7o8rcbyf/HkPh8TBTpML1jmyjbXXCy/e19722b1ce5ef27a22ac667dbfdcfb97/orange.png",
      sm: "https://images.ctfassets.net/drib7o8rcbyf/3v641DMgODIAEHVAYE3x1y/74f33740f930769d90b06823fb9385de/yellow.png",
      md: "https://images.ctfassets.net/drib7o8rcbyf/71W0cDPpmmWAHPvkKWCTb0/f36bb7dd8a7d105dfb1f326585399772/green.png",
      lg: "https://images.ctfassets.net/drib7o8rcbyf/4SYUBEQPruxWbxrAt6gklN/56a94bf3ba9bd5d1e53e031dad54633b/blue.png",
      xl: "https://images.ctfassets.net/drib7o8rcbyf/3PK14S1WcxBaeYEA1yhqOl/f6ba056eb8879d6366cf43a815c6f57b/indigo.png",
      hg: "https://images.ctfassets.net/drib7o8rcbyf/3NCCMg40RBfMmf2eq3e1SC/a51a2bc2800a44d41dee66cebd80247e/violet.png"
    };
  */
  useEffect(() => {
    if (eqxObjValue) {
      const imageUrlSwapsByBreakpointLabel = {};

      BREAKPOINTS.default.forEach((breakpoint) => {
        imageUrlSwapsByBreakpointLabel[breakpoint.label] =
          eqxObjValue[breakpoint.label];
      });

      setImageUrlsByBreakpointLabel(imageUrlSwapsByBreakpointLabel);
    }
  }, [eqxObjValue]);

  return (
    <picture
      className={classNames(styles.responsiveImage, className)}
      data-is="ResponsiveImage"
    >
      {!isLoading &&
        BREAKPOINTS.default
          .filter((breakpoint) => breakpoint.label !== BREAKPOINTS.NONE.label)
          .map((breakpoint) => {
            let url = imageUrlForBreakpointValue(breakpoint.value);

            if (url.indexOf("//") === 0) {
              url = "https:" + url;
            }

            return formats.map(({ filenameExtension, mediaType }) => {
              const progressiveFlag =
                filenameExtension === "jpg" ? "&fl=progressive" : "";

              return (
                <source
                  key={`${breakpoint.label}-${filenameExtension}`}
                  media={`(min-width: ${breakpoint.value}px)`}
                  srcSet={[
                    `${url}?fm=${filenameExtension}${progressiveFlag}&fit=fill&w=${Math.min(
                      breakpoint.value,
                      maxWidth
                    )} 1x`,
                    `${url}?fm=${filenameExtension}${progressiveFlag}&fit=fill&w=${Math.min(
                      breakpoint.value * 2,
                      maxWidth
                    )} 2x`,
                    `${url}?fm=${filenameExtension}${progressiveFlag}&fit=fill&w=${Math.min(
                      breakpoint.value * 3,
                      maxWidth
                    )} 3x`,
                  ].join(", ")}
                  type={mediaType}
                />
              );
            });
          })}

      {!isLoading && defaultImageUrl && (
        <img
          alt={altText}
          draggable="false"
          loading={lazy ? "lazy" : "eager"}
          src={`${
            (defaultImageUrl.indexOf("//") === 0 ? "https:" : "") +
            defaultImageUrl
          }?fm=jpg&fl=progressive&fit=fill&w=640`}
          style={{
            objectPosition: `${layoutInline}% ${layoutBlock}%`,
          }}
          title={title}
        />
      )}
    </picture>
  );
};

ResponsiveImage.propTypes = {
  altText: PropTypes.string,
  className: PropTypes.string,
  defaultImage: PropTypes.object,
  hgImage: PropTypes.object,
  layoutBlock: PropTypes.number,
  layoutInline: PropTypes.number,
  lazy: PropTypes.bool,
  lgImage: PropTypes.object,
  maxWidth: PropTypes.number,
  mdImage: PropTypes.object,
  smImage: PropTypes.object,
  swapKey: PropTypes.string,
  swapTimeout: PropTypes.number,
  title: PropTypes.string,
  xlImage: PropTypes.object,
  xsImage: PropTypes.object,
};

export default ResponsiveImage;
