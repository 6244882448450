import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

import FacilitiesApiClient from "@/api/client/entities/facilities";
import { getFacilitiesData } from "@/api/facilities";
import ClubList from "@/components/FindLocation/ClubList";
import GoogleSearch from "@/components/GoogleSearch";
import Loader from "@/components/Loader";
import LinkWithArrow from "@/components/links/link-with-arrow";
import useWebView from "@/hooks/useWebView";
import { getUserLocation } from "@/utils/location-service/locationService";

import styles from "./ChangeClubPanel.module.scss";

const INIT_STATE = {
  clubsDetail: [],
  isAddressSelected: false,
  loading: false,
};

const ChangeClubPanel = ({
  isOpen = false,
  joinFlow = false,
  onChangeClub,
}) => {
  const [clubsDetail, setClubsDetail] = useState(INIT_STATE.clubsDetail);
  const [isAddressSelected, setIsAddressSelected] = useState(
    INIT_STATE.isAddressSelected
  );
  const [isLoading, setIsLoading] = useState(INIT_STATE.clubsDetail);
  const isWebView = useWebView();

  /**
   *
   */
  const fetchNearbyClubs = async (lat, lng, regionData) => {
    let facilityIds = [];

    try {
      if (regionData) {
        let regionName = "";

        if (regionData.subRegion) {
          regionName = regionData.subRegion;
        } else {
          regionName = regionData.region;
        }

        const facilityList =
          await FacilitiesApiClient.getFacilityByRegion(regionName);
        let allFacilittiesId = [];
        const subRegionFacilityIds = [];

        if (
          facilityList &&
          facilityList.regionData &&
          facilityList.regionData[0].facilities
        ) {
          allFacilittiesId = facilityList.regionData[0].facilities.map(
            (item) => item.facilityId
          );
        }

        if (
          facilityList &&
          facilityList.regionData &&
          facilityList.regionData[0].subRegion
        ) {
          const facilitiesUnderSubRegion = facilityList.regionData[0].subRegion;

          facilitiesUnderSubRegion.forEach((item) => {
            item.facilitiesUnderSubRegion.forEach((subRegion) =>
              subRegionFacilityIds.push(subRegion.facilityId)
            );
          });
        }

        facilityIds = [...allFacilittiesId, ...subRegionFacilityIds];
      } else {
        facilityIds = await FacilitiesApiClient.findByLatLon(lat, lng);
      }

      const clubsData = await getFacilitiesData(facilityIds);

      clubsData?.forEach((club) => {
        delete club.featuredAmenities;
      });

      setClubsDetail(clubsData);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setClubsDetail([]);
      setIsLoading(false);
    }
  };

  /**
   *
   */
  const getUserLocationfromNavigator = useCallback(async () => {
    try {
      await getUserLocation()
        .then((coords) => {
          const { lat, lon } = coords.location || {};
          fetchNearbyClubs(lat, lon);
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    } catch (e) {
      console.error(e.code, e.message);
    }
  }, []);

  const handleInputSelect = useCallback(async (lat, lng, regionData) => {
    try {
      setIsLoading(true);
      await fetchNearbyClubs(lat, lng, regionData);
    } catch (e) {
      console.error(e);
    }

    setIsAddressSelected(true);
  }, []);

  const handleDeleteInput = () => {
    setClubsDetail([]);
    setIsAddressSelected(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isWebView) {
        await getUserLocationfromNavigator();
      }
    };

    fetchData();
  }, [getUserLocationfromNavigator, isWebView]);

  useEffect(() => {
    const pacContainer = document.querySelector(".pac-container");
    const pacTargetInput = document.querySelector(".pac-target-input");

    if (pacContainer && pacTargetInput) {
      if (isOpen && !pacTargetInput.parentElement.contains(pacContainer)) {
        document.body.removeChild(pacContainer);
        pacTargetInput.parentElement.appendChild(pacContainer);
      } else if (
        !isOpen &&
        pacTargetInput.parentElement.contains(pacContainer)
      ) {
        pacTargetInput.parentElement.removeChild(pacContainer);
        document.body.appendChild(pacContainer);
      }
    }

    return () => {
      if (
        pacContainer &&
        pacTargetInput &&
        pacTargetInput.parentElement.contains(pacContainer)
      ) {
        pacTargetInput.parentElement.removeChild(pacContainer);
        document.body.appendChild(pacContainer);
      }
    };
  }, [isOpen]);

  return (
    <section className={styles.changeClub}>
      <div className={styles.changeClubInner}>
        <div className={styles.changeClubTop}>
          <h2>100+ Clubs Worldwide</h2>
          {joinFlow ? (
            <p>Choose a club for your membership</p>
          ) : (
            <p>Choose a club to visit</p>
          )}
          <GoogleSearch
            clearSearchInput={handleDeleteInput}
            onInputSelect={handleInputSelect}
          />
        </div>

        <div className={styles.changeClubList}>
          {clubsDetail?.length === 0 && isAddressSelected && !isLoading && (
            <div className={`${styles.changeClubNoNearBy} container`}>
              <LinkWithArrow
                link="/clubs"
                linkCopy="No nearby clubs. Click here to explore all Equinox Locations."
                onClick={null}
              />
            </div>
          )}

          <ClubList
            clubs={clubsDetail}
            hideCTA={true}
            onSelect={onChangeClub}
            showThumbnail={false}
          />
        </div>

        {isLoading && (
          <div style={{ minHeight: "80vh" }}>
            <Loader />
          </div>
        )}
      </div>
    </section>
  );
};

ChangeClubPanel.propTypes = {
  isOpen: PropTypes.bool,
  joinFlow: PropTypes.bool,
  onChangeClub: PropTypes.func.isRequired,
};

export default ChangeClubPanel;
