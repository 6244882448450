import Link from "next/link";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";

import ContentfulImage from "@/components/ContentfulImage";
import LinkWithArrow from "@/components/links/link-with-arrow";
import { COOKIES } from "@/constants";
import ImageSizes from "@/utils/helpers/images";

import style from "./CardListView.module.scss";

const REGIONS = ["Midtown", "Uptown", "Downtown", "Brooklyn"];

const getRegion = (region) => {
  const reg = region.split("/")[1];

  return REGIONS.includes(reg) ? `${reg}, ` : "";
};

const renderAddress = (subcopy) => {
  const { address, city, state, zip, phone, region } = subcopy;

  return (
    <div className={`${style.address} d-flex flex-column`}>
      {address}
      <br />
      {region && `${getRegion(region)}${city}, ${state} `}{" "}
      <span className="d-none d-md-inline">{zip}</span>
      <span className="d-sm-inline d-md-none">{zip}</span>
      <br />
      {phone && phone != 0 && (
        <span className="d-lg-inline p10 pt-1">{phone}</span>
      )}
    </div>
  );
};

const CardListView = ({
  clubLabel,
  headline,
  subcopy,
  featuredAmenities,
  imgUrl,
  cta,
  id,
  url,
  featuredAmenitiesTitle,
  onClick,
  isRegionList,
  setHoverClub,
  showMap,
  focusClubId,
}) => {
  const [, setCookie] = useCookies([COOKIES.SELECTED_CLUB_ID.name]);

  const removeActiveStyle = () => {
    const allAttribute = document.querySelectorAll(`[data-club-list]`);

    allAttribute.forEach((elem) => {
      elem.classList.remove(style.activeClubItem);
    });
  };

  useEffect(() => {
    removeActiveStyle();
    const ele = document.querySelector(`[data-club-list="${focusClubId}"]`);

    if (focusClubId) {
      if (ele) {
        ele.classList.add(style.activeClubItem);

        ele.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [focusClubId, id]);

  return (
    <div
      className={`${style.cardListView}`}
      key={id}
      onMouseOver={(e) => {
        removeActiveStyle();
        showMap && setHoverClub(e, id);
      }}
      {...(!isRegionList && {
        onClick: () => {
          const cookieOptions = {
            domain:
              process.env.NEXT_PUBLIC_ENVIRONMENT === "localhost"
                ? "localhost"
                : ".equinox.com",
            maxAge: 1200,
            path: "/",
            secure: process.env.NEXT_PUBLIC_ENVIRONMENT !== "localhost",
          };
          setCookie(COOKIES.SELECTED_CLUB_ID.name, id, cookieOptions);
        },
      })}
    >
      <Link
        data-club-list={id}
        href={url}
        key={id}
        onKeyDown={
          onClick &&
          ((e) => {
            if (e.keyCode === 13 || e.keyCode === 32) {
              e.preventDefault();
              onClick();
            }
          })
        }
      >
        <div className={style.innerContent}>
          <div
            className={style.innerContainer}
            onClick={
              onClick &&
              ((e) => {
                e.preventDefault();
                onClick();
              })
            }
          >
            <div className={`${style.row}`}>
              <div
                className={`${
                  isRegionList ? "col-sm-16" : "col-sm-9"
                } col-md-11 col-lg-5 p-0`}
              >
                {clubLabel && (
                  <div className={style.clubLabel}>{clubLabel}</div>
                )}

                <div className={`${style.headline} d-flex align-items-end`}>
                  {headline}
                </div>

                {renderAddress(subcopy)}

                {cta && (
                  <LinkWithArrow
                    link={cta.link}
                    linkCopy={cta.linkCopy}
                    onClick={() => {}}
                    stylesFromParent={style.linkArrow}
                  />
                )}
              </div>
              {!showMap && (featuredAmenities || imgUrl) && (
                <div className="offset-lg-1 col-lg-5 d-none d-lg-block p-0">
                  <div className={style.amenities}>
                    {featuredAmenities && featuredAmenities.length > 0
                      ? featuredAmenitiesTitle
                        ? featuredAmenitiesTitle
                        : "Featured Amenities"
                      : ""}
                  </div>
                  <ul>
                    {featuredAmenities &&
                      featuredAmenities.map((amenity) => {
                        return <li key={amenity}>{amenity}</li>;
                      })}
                  </ul>
                </div>
              )}
              {!showMap && (
                <div
                  className={`${
                    isRegionList ? "d-sm-none d-md-block" : ""
                  } col-sm-7 col-md-5 p-0 align-self-center`}
                >
                  {imgUrl && (
                    <div className={`${style.imgContainer}`}>
                      <ContentfulImage
                        alt={headline}
                        sizes={ImageSizes.locationCard}
                        src={imgUrl}
                        title={headline}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

CardListView.propTypes = {
  clubLabel: PropTypes.string,
  cta: PropTypes.shape({
    link: PropTypes.string,
    linkCopy: PropTypes.string,
  }),
  featuredAmenities: PropTypes.array,
  featuredAmenitiesTitle: PropTypes.string,
  focusClubId: PropTypes.string,
  headline: PropTypes.string,
  id: PropTypes.string,
  imgUrl: PropTypes.string,
  isRegionList: PropTypes.bool,
  onClick: PropTypes.func,
  setHoverClub: PropTypes.func,
  showMap: PropTypes.bool,
  subcopy: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    phone: PropTypes.string,
    region: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  url: PropTypes.string,
};

export default CardListView;
