import { atcb_action } from "add-to-calendar-button";
import classNames from "classnames";
import { addMinutes, format as formatDate } from "date-fns";
import { PropTypes } from "prop-types";
import React from "react";

import styles from "./AddToCalendarButton.module.scss";

const AddToCalendarButton = ({
  children,
  className,
  duration,
  iCalFileName = "equinox_visit",
  location,
  name,
  options = ["Apple", "Google", "iCal", "Microsoft365", "Outlook.com", "Yahoo"],
  startDate,
  startTime,
  timeZone,
}) => {
  const eventStart = new Date(`${startDate}T${startTime}`);
  const eventEnd = addMinutes(eventStart, duration);

  return (
    <button
      className={classNames(styles.addToCalendarButton, className)}
      name="AddToCalendarButton - Add To My Calendar"
      onClick={() =>
        atcb_action({
          endDate: formatDate(eventEnd, "yyyy-MM-dd"),
          endTime: formatDate(eventEnd, "HH:mm"),
          iCalFileName,
          location,
          name,
          options,
          startDate,
          startTime,
          timeZone,
        })
      }
      type="button"
    >
      {children || "Add to my calendar"}
    </button>
  );
};

AddToCalendarButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  duration: PropTypes.number,
  iCalFileName: PropTypes.string,
  location: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  startDate: PropTypes.string,
  startTime: PropTypes.string,
  timeZone: PropTypes.string,
};

export default AddToCalendarButton;
