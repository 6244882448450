import { useEffect, useState } from "react";

/*
 * Monitors the window.eqxObj object for a specific key. Returns the value of the key when it is set.
 */
const useEqxObj = (key, timeout = 0) => {
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(null);

  useEffect(() => {
    window.eqxObj = window.eqxObj || {};

    let isMounted = true;
    let timeoutId;

    if (!key) {
      setIsLoading(false);
    } else if (window.eqxObj[key]) {
      setValue(window.eqxObj[key]);
      setIsLoading(false);
    } else {
      Object.defineProperties(window.eqxObj, {
        ["_" + key]: {
          configurable: true,
          value: undefined,
          writable: true,
        },
        [key]: {
          configurable: true,
          get() {
            return this["_" + key];
          },
          set(value) {
            this["_" + key] = value;

            if (isMounted) {
              clearTimeout(timeoutId);
              setValue(this["_" + key]);
              setIsLoading(false);
            }
          },
        },
      });

      timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, timeout);
    }

    return () => {
      clearTimeout(timeoutId);
      isMounted = false;
    };
  }, [key, timeout]);

  return {
    isLoading,
    value,
  };
};

export default useEqxObj;
