import Utilities from "@/utils/helpers/formatting";

module.exports = {
  CarouselWithDescCard: [
    {
      mediaQuery: "default",
      params: { h: 900, w: 900 },
    },
  ],
  breakerDesktop: [
    {
      mediaQuery: "default",
      params: { w: 1800 },
    },
  ],
  breakerMobile: [
    {
      mediaQuery: "default",
      params: { w: 768 },
    },
  ],
  callout: [
    {
      mediaQuery: "default",
      params: { fit: "fill", w: 900 },
    },
  ],
  card: [
    {
      mediaQuery: "default",
      params: { w: 900 },
    },
  ],
  classIcon: [
    {
      mediaQuery: "default",
      params: { fit: "thumb", h: 160, r: 160, w: 160 },
    },
  ],
  editorialBackground: [
    {
      mediaQuery: "default",
      params: { fit: "fill", h: 1160, q: 70, w: 900 },
    },
  ],
  editorialContentHero: [
    {
      mediaQuery: "default",
      params: { fit: "fill", h: 570, q: 70, w: 1050 },
    },
  ],
  fullScreenBackgroundDesktop: () => {
    const settings = {
      fl: "progressive",
      fm: "jpg",
      q: 55,
      w: 2200,
    };

    return Utilities.objectToQueryString(settings);
  },
  fullScreenBackgroundMobile: () => {
    const settings = {
      fl: "progressive",
      fm: "jpg",
      q: 45,
      w: 768,
    };

    return Utilities.objectToQueryString(settings);
  },
  galleryDesktop: [
    {
      mediaQuery: "default",
      params: { w: 1800 },
    },
  ],
  groupFitnessInstanceImage: [
    {
      params: { fit: "fill", h: 800, q: 70, w: 600 },
    },
  ],
  locationCard: [
    {
      mediaQuery: "default",
      params: { w: 440 },
    },
  ],
  singleCard: [
    {
      mediaQuery: "default",
      params: { fit: "fill", h: 470, q: 70, w: 900 },
    },
  ],
  singleImageCarouselMobile: [
    {
      f: "top_right",
      fit: "crop",
      params: { h: 360, w: 640 },
      q: 45,
    },
  ],
  singleImageDesktop: [
    {
      mediaQuery: "default",
      params: { fit: "fill", q: 70, w: 1900 },
    },
  ],
  singleImageMobile: [
    {
      mediaQuery: "default",
      params: { fit: "fill", q: 70, w: 800 },
    },
  ],
  sportsClubCommunity: [
    {
      mediaQuery: "default",
      params: { w: 850 },
      q: 45,
    },
  ],
};
